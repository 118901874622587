<template>
    <div class="order-booth">
        <div class="title">
            <div class="sub-title">在线预约</div>
        </div>
        <div class="have" v-if="loading">
            <el-card class="box-card" style="min-height:100%">
                <div slot="header" class="clearfix">
                    <span class="tit-s"><i class="el-icon-s-flag"></i> 预定招聘会</span>
                </div>
                <div class="zph_show">
                    <div class="pic">
                        <!-- <img :src="img" alt="" srcset=""> -->
                        <img :src="jobDetail.url" alt="">
                    </div>
                    <div class="info">
                        <div class="title">{{ jobDetail.jobName }}</div>
                        <div class="desc">
                            <div class="organizers"><i class="el-icon-reading iconstyle1"></i> 主办方: {{ jobDetail.organizer }}</div>
                            <div class="organizers"><i class="el-icon-time iconstyle1"></i> 举办时间: {{ jobDetail.holdStartTime }} 至 {{ jobDetail.holdEndTime }}</div>
                            <div class="organizers"><i class="el-icon-position iconstyle1"></i> 举办会场: {{ jobDetail.holdField }}</div>
                            <div class="organizers"><i class="el-icon-info iconstyle1"></i> 公交：{{ jobDetail.trafficRoutes }}</div>
                        </div>
                        <div class="open-tel">
                            <div class="tel-name">联系<br />电话</div>
                            <div class="tel-phone">{{ jobDetail.contactsPhone }}</div>
                            <div class="tel-n">( {{ jobDetail.contacts }} )</div>
                        </div>
                    </div>
                </div>
                <div class="order-status">
                    <!-- 未开始 -->
                    <!-- <img src="../../../assets/img/company/fair_1.png" alt="" v-if="jobDetail.status===5"> -->
                    <!-- 已开始 -->
                    <!-- <img src="../../../assets/img/company/fair_2.png" alt="" v-else-if="jobDetail.status===3"> -->
                    <!-- 进行中 -->
                    <!-- <img src="../../../assets/img/company/fair_3.png" alt="" v-else-if="jobDetail.status===1"> -->
                    <!-- 已结束 -->
                    <!-- <img src="../../../assets/img/company/fair_4.png" alt="" v-else-if="jobDetail.status===4"> -->
                    <!-- 取消 -->
                    <!-- <img src="../../../assets/img/company/fair_5.png" alt="" v-else> -->
                </div>
            </el-card>
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <span class="tit-s"><i class="el-icon-s-flag"></i> 招聘会展位图</span>
                </div>
                <div class="show-big-pic flex1">
                    <!-- <img :src="jobDetail.url" alt="" srcset=""> -->
                    <img :src="img" alt="">
                </div>
            </el-card>
            <el-card class="box-card" style="padding-bottom:70px">
                <div slot="header" class="clearfix">
                    <span class="tit-s"><i class="el-icon-s-flag"></i> 招聘会展位在线预订</span>
                </div>
                <div class="fairs-main">
                    <div class="fairs-title">
                        <div class="name">招聘会展位预定</div>
                        <div class="icons flex">
                            <span class="pic-icon flex"><img src="../../../assets/img/company/fairs_no.png" alt="" srcset="">不可预定</span>
                            <span class="pic-icon flex"><img src="../../../assets/img/company/fairs_have.png" alt="" srcset="">已被预定</span>
                            <span class="pic-icon flex"><img src="../../../assets/img/company/fairs_can.png" alt="" srcset="">可以预定</span>
                            <span class="pic-icon flex"><img src="../../../assets/img/company/fairs_start.png" alt="" srcset="">审核中</span>
                        </div>
                    </div>
                    <div class="booth-content">
                        <div class="booth-item" v-for="(item, index) in boothRegionFroms" :key="`boothRegionFroms${index}`">
                            <div class="item-tlt">{{ item.boothRegion }}</div>
                            <div class="booth-alias flex">
                                <div class="alias-item" :class="[row.status!==2?'no-drop':'pointer']" v-for="row in item.boothSeatPojos" :key="`alias-item${row.id}`">
                                    <div class="item-lable">
                                        <div class="lable-item have" v-if="row.status===1">
                                            <p><img src="../../../assets/img/company/fairs_have.png" alt=""></p>
                                            <p>已被预定</p>
                                            <p class="have">{{ row.seatNum }}</p>
                                        </div>
                                        <div class="lable-item can" v-else-if="row.status===2">
                                            <p><img src="../../../assets/img/company/fairs_can.png" alt=""></p>
                                            <p class="can">{{ row.seatNum }}</p>
                                            <p>可预定</p>
                                        </div>
                                        <div class="lable-item start" v-else-if="row.status===3">
                                            <p><img src="../../../assets/img/company/fairs_start.png" alt=""></p>
                                            <p class="start">{{ row.seatNum }}</p>
                                            <p>审核中</p>
                                        </div>
                                        <div class="lable-item no1" v-else>
                                            <p><img src="../../../assets/img/company/fairs_no.png" alt=""></p>
                                            <p class="start">{{ row.seatNum }}</p>
                                            <p>不可预定</p>
                                        </div>
                                    </div>
                                    <div class="lable-desc" :class="[row.status===1?'m27':'m127']">
                                        <div class="desc-item have" v-if="row.status===1">{{ row.firm }} <br /> <span style="margin-left:20px;font-weight:700">已预定</span></div>
                                        <div class="desc-item can" v-else-if="row.status===2">
                                            <p class="title">展位状态可预定</p>
                                            <div class="info">
                                                <p>展位号：{{ row.seatNum }}</p>
                                                <el-button size="mini" class="ac active" @click="orderBooth(row)">立即预定</el-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div class="message">
                    <div class="message-item" v-if="jobDetail.jobFairIntroduce">
                        <h3>招聘会介绍：</h3>
                        <pre class="pre-gray" v-html="jobDetail.jobFairIntroduce"></pre>
                    </div>
                    <div class="message-item" v-if="jobDetail.mediaPublicity">
                        <h3>媒体宣传：</h3>
                        <pre class="pre-gray" v-html="jobDetail.mediaPublicity"></pre>
                    </div>
                    <div class="message-item" v-if="jobDetail.jobExplain">
                        <h3>超值服务套餐：</h3>
                        <pre class="pre-gray" v-html="jobDetail.jobExplain"></pre>
                    </div>
                    <div class="message-item" v-if="jobDetail.boothSettingScheme">
                        <h3>展位设置方案：</h3>
                        <pre class="pre-gray" v-html="jobDetail.boothSettingScheme"></pre>
                    </div>
                    <div class="message-item" v-if="jobDetail.participatoryApproach">
                        <h3>参与办法：</h3>
                        <pre class="pre-gray" v-html="jobDetail.participatoryApproach"></pre>
                    </div>
                    <!-- <div class="message-item">
                        <h3>对于应聘人员：</h3>
                        <p>1、登陆网站，注册简历；</p>
                        <p>2、点击招聘会，选择单位及岗位，查看信息；</p>
                        <p>3、投递简历，并预约面试；</p>
                        <p>4、参加当天现场招聘会直接面试。</p>
                    </div> -->
                </div>
            </el-card>
        </div>
        <div class="no" v-else>
            <img src="../../../assets/img/company/loading.png" alt="" srcset="" class="loading-img">
            <p>正在加载中...</p>
        </div>
        <el-dialog
            title="选择参展职位"
            :visible.sync="dialogVisible"
            width="30%">
            <el-select v-model="chooseJobList" multiple placeholder="请选择参展职位" no-data-text="请先发布要招聘的职位">
                <el-option
                v-for="item in jobList"
                :key="item.id"
                :label="item.workName"
                :value="item.id">
                </el-option>
            </el-select>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="chooseBooth">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import companyRequest from '../../../api/company'
export default {
    created() {
        this.JobFairId = this.$route.query.id
        //没有招聘会id
        if (!this.JobFairId) {
            //跳转到招聘会预约列表
            this.$router.push({
                path: '/company/notice'
            })
        }
        this.getJobList()
        // console.log('JobFairId', this.JobFairId)
    },
    mounted() {
       this.getJobFairDetail() 
    },
    data() {
        return {
            img: '',
            loading: false,
            jobList: [], //职位列表
            dialogVisible: false, //预定展位选择职位弹出框
            boothId: null, //展位id
            chooseJobList: [], //预约选择职位
            JobFairId: null, //招聘会id
            jobDetail: {}, //招聘会详情
            boothRegionFroms: [], //展位列表
            boothLocationForm: { //预约展位信息
                id: null, //展位id
                jobId: null, //招聘会id
                positionId: '' //职位id
            } 
        }
    },
    methods: {
        //获取招聘会信息
        getJobFairDetail() {
            companyRequest.getJobFairDetail({id: this.JobFairId}).then(res => {
                if (res.code === 200) {
                    setTimeout(() => {
                        this.loading = true
                    }, 300)
                    this.img = res.data.boothPojo.floorPlan
                    this.jobDetail = res.data.jobFairPojo
                    this.boothRegionFroms = res.data.boothRegionFroms
                    this.boothRegionFroms.forEach((item, index) => {
                        item.boothSeatPojos.forEach((item1, index1) => {
                            item1.flag = true
                            // item1.status = 1
                        })
                    })
                    // console.log('this.boothRegionFroms', this.boothRegionFroms)
                }
            }).catch(err => {
                // this.$message.error('服务错误')
            })
        },
        //预定展位
        orderBooth(item) {
            // console.log(item)
            this.dialogVisible = true
            //保存展位id
            this.boothId = item.id
        },
        //确认预定展位
        chooseBooth() {
            if (this.chooseJobList.length === 0) {
                this.$message.error('请先选择参展职位')
                return
            }
            const obj = {
                id: this.boothId,
                jobId: this.JobFairId,
                positionId: this.chooseJobList.join(',')
            }
            // console.log('obj', obj)
            companyRequest.orderBoothLocation(obj).then((res) => {
                if (res.code === 200) {
                    this.$message.success('展位已预定,请等待审核中...')
                    this.dialogVisible =false
                    this.getJobFairDetail()
                }
            }).catch((err) => {
                this.$message.error(err)
                this.getJobFairDetail()
            })

        },
        //获取企业的职位列表
        getJobList() {
            companyRequest.getJobList().then((res) => {
                if (res.code === 200) {
                    this.jobList = res.data
                }
            })
        }
    },
    computed: {
        // descStyle() {
        //     if (this.open) {
        //         return {
        //             bottom: '-27px'
        //         }
        //     } else {
        //         return {
        //              bottom: '-125px'
        //         }
        //     }
        // }
    }
}
</script>
<style lang="scss" scoped>
@import '../../../assets/css/global.scss';
.order-booth{
    height: 100%;
    .box-card{
        position: relative;
    }
    .el-select{
        width: 100%;
    }
    .tit-s{
        font-size: 15px;
        color: $main;
    }
    .zph_show{
        display: flex;
        margin-bottom: 20px;
        .pic{
            width: 320px;
            height: 212px;
            margin-right: 30px;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .info{
            width: 50%;
            .title{
                font-size: 20px;
                font-weight: 700;
                line-height: 46px;
                margin-bottom: 10px;
            }
            .desc{
                margin-bottom: 10px;
                .organizers{
                    padding: 3px 0;
                    color: #666;
                }
            }
            .open-tel{
                display: flex;
                align-items: center;
                min-width: 410px;
                width: 50%;
                padding: 5px 20px;
                border: 2px solid $main;
                .tel-name{
                    line-height: 18px;
                    padding-right: 9px;
                    border-right: 1px solid #eee;
                }
                .tel-phone{
                    margin-left: 20px;
                    color: $main;
                    font-size: 28px;
                    font-weight: 700;
                    letter-spacing: 2px;
                }
                .tel-n{
                    margin-left: 7px;
                    color: #666;
                }
            }
        }
    }
    .order-status{
        position: absolute;
        top: 0;
        right: 0;
    }
    .show-big-pic{
        max-height: 600px;
        padding-bottom: 20px;
    }
    .fairs-main{
        .fairs-title{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .name{
                font-size: 24px;
                text-align: center;
                line-height: 60px;
                color: #333;
            }
            .icons{
                .pic-icon{
                height: 30px;
                align-items: center;
                margin-right: 15px;
                    img{
                        width: 28px;
                        margin-right: 5px;
                    }
                }
            }
        }
        .booth-content{
            margin: 30px 60px 20px 60px;
            border: 1px solid #eee;
            border-radius: 6px;
            .booth-item{
                .item-tlt{
                    line-height: 72px;
                    height: 72px;
                    overflow: hidden;
                    text-align: center;
                    color: #101213;
                    font-size: 16px;
                    font-weight: 800;
                    border-bottom: 1px solid #eee;
                }
                .booth-alias{
                    flex-wrap: wrap;
                    padding: 10px 20px;
                    .alias-item{
                        position: relative;
                        width: 13%;
                        border-radius: 4px;
                        margin-right: 16px;
                        margin-bottom: 16px;
                        user-select: none;
                        border: 1px solid #eee;
                        border-radius: 3px;
                        &.no-drop{
                            cursor:not-allowed;
                        }
                        &.pointer{
                            cursor: auto;
                        }
                        &:hover{
                            .lable-desc{
                                transform: scale(1);
                                z-index: 100;
                                opacity: 1;
                                // display: block;
                            }
                        }
                        .item-lable{
                            .lable-item{
                                padding: 10px 0;
                                p{
                                    text-align: center;
                                    padding: 3px;
                                    font-size: 12px;
                                    color: #999;
                                    &.have{
                                        color: #f56c6c;
                                        font-weight: 700;
                                    }
                                    &.can{
                                        color: #5cb87a;
                                        font-weight: 700; 
                                    }
                                    &.start{
                                        color: #e6a23c;
                                        font-weight: 700; 
                                    }
                                    &.no{
                                        color: #000;
                                        font-weight: 700; 
                                    }
                                }
                                &.have{
                                    background: #f3efef;
                                }
                                &.can{
                                    background: #fff;
                                }
                                &.start{
                                    background: #f8e0bb;
                                }
                                &.no1{
                                    background: #eee;
                                }
                            }
                        }
                        .lable-desc{
                            z-index: -100;
                            position: absolute;
                            width: 250px;
                            left: 0;
                            // bottom: 0;
                            opacity: 0;
                            // display: none;
                            &.m27{
                                // bottom: -27px;
                                bottom: -50px;

                            }
                             &.m127{
                                bottom: -120px;
                            }
                            transform: scale(.5);
                            transition: all .3s;
                            // bottom: -27px;
                            .desc-item{
                                text-align: center;
                                &.have{
                                    padding: 10px;
                                    color: #fff;
                                    text-align: center;
                                    line-height: 20px;
                                    background: #f56c6c;
                                }
                                &.can{
                                    .title{
                                        padding: 10px;
                                        color: #fff;
                                        background: #5cb87a;
                                        font-size: 13px;
                                    }
                                    .info{
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;
                                        margin-top: -15px;
                                        // padding: 30px;
                                        padding: 30px 10px 30px 10px;
                                        background:#fffcf0;
                                        .ac{
                                            &:hover{
                                                background: $hover;
                                            }
                                        }
                                    }
                                }
                                &.start{

                                }
                                
                            }
                        }
                    }
                    // .alias-item:nth-child(2n+8){
                    //     margin-right: 0;
                    // }
                }
            }
        }
    }
    .message{
       .message-item{
           margin-bottom: 20px;
           h3{
               font-size: 14px;
               font-weight: 700;
               margin-bottom: 10px;
           }
           p{
               font-size: 12px;
               color: #888;
               line-height: 20px;
           }
       } 
    }
}
// .item-lable{
//     width: 100%;
//     height: 100%;
// }
// .popper{
//     padding: 0 !important;
//     margin: 0 !important;
// }
// .item-info{
//     padding: 10px;
//     text-align: center;
//     color: #fff;
//     font-weight: 550;
//     background: #f56c6c;
//     .order-done{
//         margin-left: 20px;
//     }
// }
// .item-lable{
//     padding: 5px 0;
//     p{
//         img{
//             width: 28px;
//         }
//         text-align: center;
//         padding: 4px 0;
//         font-size: 12px;
//         color: #999;
//         &.danger{
//             font-weight: 700;
//             color: #f56c6c;
//         }
//         &.success{
//           color: #5cb87a;
//           font-weight: 700;  
//         }
//     }
// }
</style>